.navbar {
    background-color: #000;
    position: fixed;
    width: 100%;
    z-index: 1;

    &-wrapper {
        color: #fff;
        height: 54px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;

        @media (min-width: 768px) {
            justify-content: space-between;
            padding: 0 80px !important;
        }

        @media (min-width: 1024px) {
            padding: 0 96px !important;
        }

        @media (min-width: 1280px) {
            padding: 0 144px !important;
        }

        .mobile-nav {
            display: block;
            position: absolute;
            left: 20px;
            top: 14px;

            &-icon {
                position: relative;
                width: 25px;
                height: 25px;

                &.menu {
                    display: block;

                    &.hide-menu-icon {
                        display: none;
                    }
                }

                &.cancel {
                    display: none;

                    &.show-cancel-icon {
                        display: block;
                    }
                }
            }

            @media (min-width: 768px) {
                display: none;
            }
        }

        .site-name {
            font-weight: 700;
            font-size: 20px;
        }

        .site-options {
            display: none;
            flex-direction: column;
            position: absolute;
            background-color: #000;
            top: 54px;
            border-top: 1px solid #ffffff;
            height: auto;
            width: 100%;

            @media (min-width: 768px) {
                position: relative;
                display: flex !important;
                flex-direction: row;
                font-weight: 300;
                top: 0;
                border: unset;
                width: unset;
            }

            a {
                font-size: 18px;
                padding-left: 50px;
                padding-top: 25px;
                padding-bottom: 25px;
                border-top: 1px solid #dbdbdb;

                @media (min-width: 768px) {
                    padding: 0;
                    border-top: unset;
                    
                    &:hover {
                        text-decoration: underline;
                    }
    
                    &:nth-child(1) {
                        padding-right: 16px;
                    }

                    &:nth-child(2) {
                        padding-right: 16px;
                        padding-left: 16px;
                    }

                    &:nth-child(3) {
                        padding-right: 16px;
                        padding-left: 16px;
                    }

                    &:nth-child(4) {
                        padding-left: 16px;
                    }
                }
            }

            &.show-site-options {
                display: flex;
            }
        }
    }
}
