.creatorsNamesRow1{
    display: flex;
    flex-direction: row;
}

.manuelsName{
    margin-top: 70px;
    margin-left: 100px;
}

.vijaysName{
    margin-top: 80px;
    margin-left: 430px;

}

.gisellesName {
    margin-top: 80px;
    margin-left: 410px;
}

.picturesOfCreatorsRow1 {
    display: flex;
    flex-direction: row;
   
}

.pictureOfManuel {
    margin-right: 120px;
    margin-left: 70px;

}

.pictureOfVijay {
    margin-left: 260px;
    margin-top: 60px;

}

.pictureOfGiselle {
    margin-left: 360px;
    margin-top: 40px;
}

.descriptions {
    display: flex;
    flex-direction: row;
}

.manuelsDescription {
    margin-right:70px;
    margin-left: 10px;

}

.vijaysDescription {
    margin-right: 100px;
    margin-left: 100px;

}

.gisellesDescription {
    margin-left: 70px;

}

.socialButtons{
    display:flex;
    flex-direction: row;
}

.manuelsButtons{
    margin-right: 100px;
    margin-left: 100px;
}
.linkden1{
    margin-right:10px;
}

.vijaysButtons{
    margin-left: 350px;
    margin-right: 100px;
}

.linkden2{
    margin-right: 10px;
}

.gisellesButtons{
    margin-left: 350px;
}

.linkden3{
    margin-right: 10px;
}