
.pictures {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 50px;
    margin-right: 50px;
}
.picture1 {
    margin-right: 50px;
}
.usernames {
    display: flex;
    flex-direction: row;
}
.username1 {
    margin-right: 200px;
    margin-left: 50px;
}

.donate-button {
    display: inline-block;
    background-color:#7b38d8;
    border-radius: 10px;
    border: 4px double #cccccc;
    color: white;
    text-align: center;
    font-size: 20px;
    padding: 10px;
    width: 100px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;

}