$primary: #4da6ff;

.options-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 768px) {
        align-items: unset;
        padding: 3% 0;
        flex-direction: row;
        justify-content: space-between;
    }
}

.Connect-Options {
    width: 80%;
    min-width: 245px;
    max-width: 300px;
    box-shadow: 0px 1rem 1.5rem rgba(0,0,0,0.5);
    transition: all 0.3s ease-in-out;
    margin: 40px 0;
    position: relative;

    @media screen and (min-width: 768px) {
        width: 30%;
        min-width: 200px;
        max-width: 400px;
        margin: 0;
    }

    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        box-shadow: 0 5px 15px rgba(49, 46, 46, 0.3);
        transition: opacity 0.3s ease-in-out;
    }

    &:hover {
        transform: scale(1.2, 1.2);
    }

    &:hover::after {
        opacity: 1;
    }

    a {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;

        .connect-options-img {
            object-fit: cover;
            object-position: 34% 10%;
            box-shadow: 0px 1rem 1.5rem rgba(0,0,0,0.5);
        }
        
        .options-description {
            width: 80%;
            height: 30%;
            position: relative;
            bottom: 36%;
            left: 7%;
            font-weight: 100;
            text-shadow: 1px 1px #000;
            box-shadow: 0px 1rem 1.5rem rgba(0,0,0,0.5);
            border: 1px solid rgba($primary , 0.8);
            border-radius: 20px;
            padding: 10px 20px;
        }
    }
}
