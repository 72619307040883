.footer {
    position: relative;
    // top: 54px;
    width: 100%;
    height: 50%;
    background-color: #000;
    color: #fff;
    display: flex;
    font-size: 12px;

    @media (min-width: 485px) {
        height: 75px;
    }

    @media (min-width: 904px) {
        height: 54px;
    }
    
    &--wrapper {
        display: flex;
        flex-direction: column;
        margin: 15px 40px 20px 40px;
        align-items: center;
        text-align: center;
        justify-content: space-between;

        @media (min-width: 485px) {
            width: 100%;
            margin: 15px 0 20px 0;
        }

        @media (min-width: 904px) {
            flex-direction: row;
            padding: 0 22px;
            margin: 0 69px;
        }

        @media (min-width: 1024px) {
            margin: 0 81px;
        }

        @media (min-width: 1280px) {
            margin: 0 107px;
        }

        @media (min-width: 1536px) {
            margin: 0 170px;
        }

        .copyright {
            display: inline-block;
            margin-bottom: 10px;
            margin: 0 0 10px 0;

            @media (min-width: 485px) {
                margin: 0 0 0 0;
            }

            @media (min-width: 904px) {
                margin: 0 30px 0 0;
            }
        }

        .legal-links {
            .privacy-policy, .terms-of-use, .contact-us {
                border-right: 1px solid #d2d2d7;
                margin-right: 7px;
                padding-right: 10px;
                display: inline-block;
            }

            a:hover, a:active{
                text-decoration: underline;
            }
        }
    }
}
