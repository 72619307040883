$primary: #4da6ff;
$secondary: #77ff33;
$tertiary: #b25dc7;
$empty: #bebebe;

.submit-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .submit-sync-button {
        border-radius: 20px;
        box-shadow: 0 0 7px rgba($primary, 0.8);
        color: rgba($secondary, 0.5);
        text-transform: uppercase;
        position: relative;
        font-family: system-ui, sans-serif;
        font-size: 23px;
        background: white;
        position: relative;
        padding: 20px 90px;
        letter-spacing: 1px;

        &:focus {
            outline: 0;
        }

        &[disabled] {
            opacity: 0.5;
            cursor: not-allowed;
            color: rgba($tertiary, 0.5);

            &:hover,
            &:active {
                box-shadow: 0 0 7px rgba($primary, 0.8);
            }
        }

        &:hover,
        &:active {
            box-shadow: 0 0 15px $primary;
        }
    }
}
