.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 54px;
    padding: 0 10px;
    min-height: calc(100vh - 117px);

    @media (min-width: 387px) {
        min-height: calc(100vh - 99px);
    }

    @media (min-width: 466px) {
        min-height: calc(100vh - 81px);
    }


    @media (min-width: 485px) {
        min-height: calc(100vh - 75px);
    }

    @media screen and (min-width: 768px) {
        width: 100%;
    }

    @media (min-width: 904px) {
        min-height: calc(100vh - 54px);
    }

    @media screen and (min-width: 1024px) {
        width: 1000px;
    }

    @media screen and (min-width: 1280px) {
        width: 1132px;
    }

    @media screen and (min-width: 1536px) {
        width: 1250px;
    }
}

.authorization-wrapper {
    display: grid;
    grid-template-rows: 1fr 1fr;
    padding: 86px 0 65px 0;
    justify-items: center;
    row-gap: 130px;

    @media screen and (min-width: 768px) {
        row-gap: unset;
        grid-template-rows: unset;
        grid-template-columns: 1fr 1fr;

    }
}

.double-authorizer-wrapper {
    display: flex;
    padding: 86px 0 65px 0;
    justify-content: center;
}
