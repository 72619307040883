$primary: #4da6ff;
$text-color: #65676b;
$color-white: #fff;
$spotify-primary: #1DB954;
$applemusic-primary: #f94c57;

.music-wrapper {
    width: 90%;
    box-shadow: 0 0 7px rgba($primary , 0.8);
    padding: 10px 20px;
    border-radius: 20px;

    &:hover, &:active {
        box-shadow: 0 0 15px rgba(77, 166, 255, 0.8);

        .music-header {
            box-shadow: 0 0 15px rgba(77, 166, 255, 0.8);
        }
    }

    @media screen and (min-width: 768px) {
        width: 80%;
        padding: 10px 20px;
    }
}

.music-header {
    position: relative;
    padding: 30px 10px;
    box-shadow: 0 0 5px rgba($primary , 0.8);
    border-radius: 20px;
    top: -60px;
    background-color: $color-white;
    font-family: system-ui, sans-serif;
    display: flex;
    justify-content: center;
    text-transform: capitalize;

    a {
        &.header-title-apple {
            &:hover, &:active {
                color: $applemusic-primary;
            }
        }

        &.header-title-spotify {
            &:hover, &:active {
                color: $spotify-primary;
            }
        }
    }
}

.authorizer-button-wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    font-family: system-ui, sans-serif;
    font-size: 25px;
}

.playlist-checker {
    
    p.music-card-description {
        font-size: 18px;
        color: $text-color;
        font-family: system-ui, sans-serif;
        text-align: center;
    }

    p.music-card-note {
        margin-top: 15px;
        font-size: 12px;
        font-style: italic;
        text-align: center;
        font-family: system-ui, sans-serif;
        color: $text-color;

    }

}
